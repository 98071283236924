import './news_styles/mininews.css'
import eyes from '../../image/newsImgs/newsGr.svg';
import newsimgs from '../../image/newsImg.png'

export default function MoreNewsMini() {
        return (
            <>
            <div className="infocont">
                <div className="iresurce_con">
                <div className='titlepos'>
                    <p className="iresurce_title">Больше новостей</p>
                    <button className='allbtn' type="submit">Все</button>
                </div>
                    <div className='newsblocpos'>
                    <div className='newsbloc'>
                        <img src={newsimgs} alt="news photo" />
                        <h3>К 1 ИЮНЯ «МЕЖДУНАРОДНОМУ ДНЮ ЗАЩИТЫ ДЕТЕЙ» ПРЕПОДАВАТЕЛИ КАФЕДРЫ ПЕДАГОГИКИ И ФИЛОЛОГИИ</h3>
                        <div className='datapublication'>
                            <h4>10/06/24</h4>
                            <div className='datapublics'>
                                <img src={eyes} alt="" />
                                <h4>163</h4>
                            </div>
                        </div>
                    </div>

                    <div className='newsbloc'>
                        <img src={newsimgs} alt="news photo" />
                        <h3>К 1 ИЮНЯ «МЕЖДУНАРОДНОМУ ДНЮ ЗАЩИТЫ ДЕТЕЙ» ПРЕПОДАВАТЕЛИ КАФЕДРЫ ПЕДАГОГИКИ И ФИЛОЛОГИИ</h3>
                        <div className='datapublication'>
                            <h4>10/06/24</h4>
                            <div className='datapublics'>
                                <img src={eyes} alt="" />
                                <h4>163</h4>
                            </div>
                        </div>
                    </div>

                    <div className='newsbloc'>
                        <img src={newsimgs} alt="news photo" />
                        <h3>К 1 ИЮНЯ «МЕЖДУНАРОДНОМУ ДНЮ ЗАЩИТЫ ДЕТЕЙ» ПРЕПОДАВАТЕЛИ КАФЕДРЫ ПЕДАГОГИКИ И ФИЛОЛОГИИ</h3>
                        <div className='datapublication'>
                            <h4>10/06/24</h4>
                            <div className='datapublics'>
                                <img src={eyes} alt="" />
                                <h4>163</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        )
    }

import '../../components/btns/btns_styles.css';
import './magistrtura_styles.css'
import Tables from '../../components/tables/Tables'




export default function Magistratura() {
    return (
        <>
            <div class="btn_position">
                <a class="btns" href="#">Магистратура</a>
                <a class="btns" href="#">Квоты приема</a>
            </div>
            <Tables />
        </>
    )

}
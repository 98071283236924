import './MainBannerStyle.css'
import researchAndLearn from '../../image/researchAndLearn.gif'

const MainBanner = () => {
   return ( <>
       <div id="topMenuBanner">
           <div class="menupsition"> 
	   {/* <div class="banner-right"> */}
           	 <p id="enterText">ВОЙДИТЕ В СИСТЕМУ ДЛЯ ДОСТУПА</p>
            	 <button id="enterButton">Войти</button>
                 <div className='researchimg'>
            	     <img id="studentImage" src={researchAndLearn}/>
                 </div>          
       	     </div>
	</div>

        {/*  Dekanat */}

        <div className="infocont">
                <div className="iresurce_con">
                    <p className="iresurce_title">Деканат</p>
                    <div className="iresurce_boxes">
                        <div className="iresurce_boxes_top">
                            <div className="iresurce_boxes_top_box">
                            </div>
                            <div className="iresurce_boxes_top_box">
                            </div>
                            <div className="iresurce_boxes_top_box">
                            </div>
                        </div>
                        <div className="iresurce_boxes_bottom">
                            <div className="iresurce_boxes_bottom_box">
                            </div>
                            <div className="iresurce_boxes_bottom_box">

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/*  Student life */}
            <div className="infocont">
                <div className="iresurce_con">
                    <p className="iresurce_title">Cтудентческая жизнь</p>
                    <div className="iresurce_boxes">
                        <div className="iresurce_boxes_top">
                            <div className="iresurce_boxes_top_box">
                            </div>
                            <div className="iresurce_boxes_top_box">
                            </div>
                            <div className="iresurce_boxes_top_box">
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* Lesson Schedule  */}
            <div className="infocont">
                <div className="iresurce_con">
                    <p className="iresurce_title">Расписание уроков</p>
                    <div className="iresurce_boxes">
                        <div className="resurce_boxes_top">
                            <div className="iresurce_boxes_top_box">
                            </div>
                            <div className="iresurce_boxes_top_box">
                            </div>
                            <div className="iresurce_boxes_top_box">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </>)
}


export {MainBanner}

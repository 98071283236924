import React, { useEffect, useRef, useState, useLayoutEffect, useContext } from 'react';
import './home.css';
import title_ru from '../../image/title_ru.svg';
import title_en from '../../image/title_en.svg';
import title_uz from '../../image/title_uz.svg'
import headerUser from '../../image/userHomeHeader.svg';
import ranchAfter from '../../image/ranchAfter.svg';
import ranchBefore from '../../image/ranchBefore.svg';
import ranchLogoReft1 from '../../image/logoRight1.svg';
import ranchLogoReft2 from '../../image/logoRight2.svg';
import ranchLogoReft3 from '../../image/logoRight3.svg';
import operatorSmile from '../../image/operatorSmail.svg';
import instagramIcon from '../../image/instagramIcon.svg';
import telegramIcon from '../../image/telegramIcon.svg';
import youtubeIcon from '../../image/youtubeIcon.svg';
import mailIcon from '../../image/mailIcon.svg';
import HomeAboutSwiper from '../../components/homeAboutSwiper/HomeAboutSwiper';
import instaEffect from '../../image/gifInsta.gif';
import usernot from '../../image/userNot.svg';
import newsImg from '../../image/newsImg.png'
import { IoEyeSharp } from "react-icons/io5";
import text50 from '../../image/animText/text.svg'
import text50Bbg from '../../image/animText/Subtract.svg'
import garphLine from '../../image/studyIcons/garphLine.svg'
import monyIcon from '../../image/studyIcons/money.svg'
import manageUserIcon from '../../image/studyIcons/manageUser.svg'
import marketingIcon from '../../image/studyIcons/marketingIcon.svg'
import golobalIcon from '../../image/studyIcons/globalIcon.svg'
import headIcon from '../../image/studyIcons/miyaIcon.svg'
import CalIcon from '../../image/studyIcons/Calculate.svg'
import KiICon from '../../image/studyIcons/KiIcon.svg'
import KbiIcon from '../../image/studyIcons/KbIIcon.svg'
import SetIcon from '../../image/studyIcons/setIcon.svg'
import CarIcon from '../../image/studyIcons/carIcon.svg'
import BookIcon from '../../image/studyIcons/bookIcon.svg'
import BankIcon from '../../image/studyIcons/bankIcon.svg'
import enrgyIcon from '../../image/studyIcons/enrgyIcon.svg'
import masterSetIcon from '../../image/studyIcons/masterSet.svg'
import arrowBoxIcon from '../../image/studyIcons/arrowBoxIcon.svg'
import BollIcon from '../../image/studyIcons/bollIcon.svg'
import MenuBookIcon from '../../image/studyIcons/menuBook.svg'
import plan_vdImg from '../../image/plan_vd.png'
import unBg from '../../image/unBg.svg'
import unImg2 from '../../image/unImg2.svg'
import plan1 from '../../image/plan1.png'
import plan2 from '../../image/plan2.png'
import plan3 from '../../image/plan3.png'
import plan4 from '../../image/plan4.png'
import partnorIMg from '../../image/partnorImg2.svg'
import { MdPhonelinkRing } from "react-icons/md";
import { MdMailOutline } from "react-icons/md";
import Footer from '../../components/footer/Footer';
import shoir from '../../image/shoir2.png';
import menu1 from '../../image/rightMenuBtns/menu.svg';
import menu2 from '../../image/rightMenuBtns/Rectangle 3 (2).svg';
import menuBg from '../../image/ranchAfter.png'
import nazadMenu from '../../image/rightMenuBtns/nazad.svg'
import ranchBgRes from '../../image/BgRanchRes.svg'
import ranchRes from '../../image/ranchRes1.png'
import { Link } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import Loading from '../../components/loading/Loading';
import gif from '../../image/gif.png'
import axios from 'axios';
import AppContext from '../../context/AppContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import SwiperCore from 'swiper';
import haqiqat from '../../image/Haqiqat34.png'
import { RxExit } from "react-icons/rx";

// Swiper modullarini ishga tushirish
SwiperCore.use([Autoplay]);


export default function Home() {


    const { leng } = useContext(AppContext);


    // loader
    const [loading, setLoading] = useState(true);

    // Function to pre-load images
    const preloadImages = (imageSources) => {
        return new Promise((resolve, reject) => {
            let loadedImages = 0;
            const totalImages = imageSources.length;

            imageSources.forEach((src) => {
                const img = new Image();
                img.src = src;
                img.onload = () => {
                    loadedImages += 1;
                    if (loadedImages === totalImages) {
                        resolve();
                    }
                };
                img.onerror = reject;
            });
        });
    };

    useEffect(() => {
        const images = [
            title_ru,
            headerUser,
            // add all other image sources here
        ];

        preloadImages(images)
            .then(() => setLoading(false))
            .catch((error) => console.error('Error loading images:', error));
    }, []);

    // scroll effect fixed title
    const [scrollStatus, setScrollStatus] = useState(false);
    const [scrollStatus2, setScrollStatus2] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY || window.pageYOffset;
            setScrollStatus(scrollPosition >= 40);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY || window.pageYOffset;
            setScrollStatus2(scrollPosition >= 460);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => setScrollPosition(window.scrollY);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const calculateColor = (index) => {
        const position = 4200 + 200 * index;
        return scrollPosition > position ? '#f56100' : 'black';
    };

    // Matn
    const newsTitle = leng == 'ru' ? "Новости" : leng == 'en' ? "News" : "Yangilik"
    const newTitle2 = leng == 'ru' ? "Планы - 2030" : leng == 'en' ? "Plans - 2030" : "Rejalar - 2030"

    const [scrollPosition2, setScrollPosition2] = useState(0);

    useEffect(() => {
        const handleScroll = () => setScrollPosition2(window.scrollY);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const calculateColor2 = (index) => {
        const position = 8800 + 65 * index;
        return scrollPosition2 > position ? '#f56100' : 'white';
    };

    // marquee
    const marqueeContainerRef = useRef(null);
    const marqueeContentRef = useRef(null);

    useLayoutEffect(() => {
        const marqueeContent = marqueeContentRef.current;
        const marqueeContainer = marqueeContainerRef.current;

        if (marqueeContent && marqueeContainer) {
            const containerWidth = marqueeContainer.offsetWidth;
            const contentWidth = marqueeContent.scrollWidth;

            // Ensure text width is twice the container width
            if (contentWidth < containerWidth * 2) {
                marqueeContent.innerHTML += marqueeContent.innerHTML;
            }
        }
    }, []);

    // right menu ON || OFF function
    const [btnClassStatus, setClassStatus] = useState('noneBtn');
    const [openMenu, setOpenMenu] = useState(false);
    const [openStudentMenu, setOpenStudentMenu] = useState(false);

    useEffect(() => {
        let timer;
        if (!openMenu && !openStudentMenu) {
            timer = setTimeout(() => {
                setClassStatus('');
            }, 1000);
        } else {
            setClassStatus('noneBtn');
        }
        return () => clearTimeout(timer);
    }, [openMenu, openStudentMenu]);

    const closeRightStudentMenu = () => setOpenStudentMenu(false);
    const closeMenu = () => setOpenMenu(false);


    const [newsData, setNewsData] = useState([])
    const getNewsData = async () => {
        const response = await axios.get(`https://new.utu-ranch.uz/news/yangiliklar/`);
        setNewsData(response.data)
    }

    const [dataPartNor, setDataPartNor] = useState([])
    const getPartnor = async () => {
        const response = await axios.get(`https://new.utu-ranch.uz/api/hamkorlar/`);
        setDataPartNor(response.data)
    }


    const [images, setImages] = useState([]);

    const getImages = async () => {
        try {
            const response = await axios.get(`https://new.utu-ranch.uz/api/homecontent/`);
            setImages(response.data);
        } catch (error) {
            console.error("Error fetching images:", error);
        } finally {
            setLoading(false); // Set loading to false whether successful or not
        }
    };

    useEffect(() => {
        getImages();
        getPartnor()
        getNewsData()
    }, []);

    if (loading) {
        return <Loading /> // Render loading indicator if still loading
    }

    // lenguage


    const translations = {
        'ru': {
            university: "Университет",
            about: "Об Университете",
            departments: "Кафедры",
            centers: "Центры и Отделы",
            rectorate: "Ректорат",
            rules: "Правила Университета",
            ethics: "Кодекс этики",
            charter: "Устав Университета",
            abutrient: "Абитуриент",
            fullTime: "Очные направления образования",
            fullTimeCourse: "Название курсов очного обучения (срок обучения 4 года)",
            correspondence: "Заочное направление оброзавания",
            onlineAdmission: "Онлайн подача документов",
            master: "Магистратура",
            library: "Онлайн библиотека",
            weHave: "У нас",
            teachers: "Профессиональных Учителей",
            popularFields: "Востребованных Направлений",
            coolStudents: "Крутых Студентов",
            studentLife: "Жизнь студентов",
            onlineAdmissionTitle: "Онлайн подача документов",
            onlineAdmissionPrompt: "Готовитесь к поступлению в университет? Заполните форму прямо сейчас и начните свой путь к высшему образованию.",
            submissionDocuments: "Подача документов",
            resources: "Ресурсы",
            eLibrary: "Электронная библиотека",
            fieldsOfStudy: "НАПРАВЛЕНИЯ ОБУЧЕНИЯ",
            moreNews: "Больше новостей",
            details: "Подробно",
            newCampusDevelopment: "Создание новых учебных корпусов и общежитий. Расширение кампуса для улучшения условий обучения и проживания студентов.",
            sportsInfrastructure: "Развитие спортивной инфраструктуры. Построение современных спортивных комплексов и площадок для содействия физическому развитию студентов.",
            advancedTechLearning: "Внедрение передовых технологий обучения. Использование виртуальной реальности и искусственного интеллекта для создания инновационных образовательных программ.",
            internationalCooperation: "Расширение международного сотрудничества. Увеличение числа программ обмена и двойных дипломов с ведущими мировыми университетами.",
            partners: "Наши Партнеры",
            news: "Новости",
            publicEvents: "Публичные мероприятия",
            internationalRelations: "Международные отношения",
            loginToAccess: "Войдите в систему для доступа",
            loginToAccess: "Войдите в систему для доступа",
            login: "Войти"
        },
        'uz': {
            university: "Universitet",
            about: "Universitet haqida",
            departments: "Kafedralar",
            centers: "Markaz va boʻlimlar",
            rectorate: "Rektorat",
            rules: "Universitet qoidalari",
            ethics: "Axloq kodeksi",
            charter: "Universitet nizomi",
            abutrient: "Abituriyent",
            fullTime: "Kunduzgi ta’lim yoʻnalishlari",
            fullTimeCourse: "Kunduzgi ta’lim yoʻnalishlari nomi (oʻqish muddati 4 yil)",
            correspondence: "Sirtqi taʻlim yoʻnalishlari",
            onlineAdmission: "Onlayn ariza topshirish",
            master: "Magistratura",
            library: "Onlayn kutubxona",
            weHave: "Bizda",
            teachers: "Professional oʻqituvchilar",
            popularFields: "Talabgor yoʻnalishlar",
            coolStudents: "Ajoyib talabalar",
            studentLife: "Talabalik hayoti",
            onlineAdmissionTitle: "Hujjatlarni onlayn topshirish",
            onlineAdmissionPrompt: "Universitetga kirishga tayyormisiz? Hoziroq shaklni toʻldiring va oliy taʼlimga sayohatingizni boshlang.",
            submissionDocuments: "Hujjatlarni topshirish",
            resources: "Resurslar",
            eLibrary: "Elektron kutubxona",
            fieldsOfStudy: "TA’LIM YOʻNALISHLARI",
            moreNews: "Koʻproq yangiliklar",
            details: "Tafsilotlar",
            newCampusDevelopment: "Yangi o'quv binolari va yotoqxonalarni barpo etish. Talabalarning o‘qish va yashash sharoitlarini yaxshilash maqsadida kampusni kengaytirish.",
            sportsInfrastructure: "Sport infratuzilmasini rivojlantirish. O‘quvchilarning jismoniy rivojlanishiga ko‘maklashish maqsadida zamonaviy sport majmualari va maydonchalari barpo etilmoqda.",
            advancedTechLearning: "Ta’limning ilg’or texnologiyalarini joriy etish. Innovatsion ta’lim dasturlarini yaratish uchun virtual haqiqat va sun’iy intellektdan foydalanish.",
            internationalCooperation: "Xalqaro hamkorlikni kengaytirish. Dunyoning yetakchi universitetlari bilan almashinuv va qoʻsh diplom dasturlari sonini koʻpaytirish.",
            partners: "Bizning hamkorlar",
            news: "Yangiliklar",
            publicEvents: "Ommaviy tadbirlar",
            internationalRelations: "Xalqaro munosabatlar",
            loginToAccess: "Koʻrish uchun tizimga kiring",
            loginToAccess: "Koʻrish uchun tizimga kiring",
            login: "Kirish"
        },
        'en': {
            university: "University",
            about: "About University",
            departments: "Departments",
            centers: "Centers and other departments",
            rectorate: "University administration",
            rules: "University Rules",
            ethics: "Code of Ethics",
            charter: "University charter",
            abutrient: "Applicant",
            fullTime: "Full-time education programs",
            fullTimeCourse: "Name of full-time courses (4 years of study)",
            correspondence: "Correspondence courses of education",
            onlineAdmission: "Online admission",
            master: "Master's degree",
            library: "Online library",
            weHave: "We have",
            teachers: "Professional teachers",
            popularFields: "Popular areas of study",
            coolStudents: "Cool Students",
            studentLife: "Students' life",
            onlineAdmissionTitle: "Online submission of documents",
            onlineAdmissionPrompt: "Are you preparing to enter university? Fill out the form now and start your journey to higher education.",
            submissionDocuments: "Submission of documents",
            resources: "Resources",
            eLibrary: "E-library",
            fieldsOfStudy: "FIELDS OF STUDY",
            moreNews: "More news",
            details: "More detail",
            newCampusDevelopment: "Creation of new academic buildings and dormitories. Expansion of the campus to improve the conditions for students to study and live.",
            sportsInfrastructure: "Development of sports infrastructure. Construction of modern sports complexes and grounds to promote physical development of students.",
            advancedTechLearning: "Implementation of advanced learning technologies. Using virtual reality and artificial intelligence to create innovative educational programs.",
            internationalCooperation: "Expansion of international cooperation. Increase in the number of exchange programs and double degrees with leading world universities.",
            partners: "Our Partners",
            news: "News",
            publicEvents: "Public events",
            internationalRelations: "International relations",
            loginToAccess: "Log in to the system for access",
            loginToAccess: "Log in to the system for access",
            login: "Log In"
        }
    }

    const currentTranslations = translations[leng];

    // translate НАПРАВЛЕНИЯ


    const textYonalish = [
        [
            {
                "code": "603.01",
                "ru": "Экономика",
                "en": "Economics",
                "uz": "Iqtisodiyot",
                "icon": garphLine // Tasvir manbasi
            },
            {
                "code": "6041030",
                "ru": "Бухгалтерский учёт и аудит",
                "en": "Accounting and Auditing",
                "uz": "Buxgalteriya hisobi va audit",
                "icon": monyIcon
            },
            {
                "code": "60310300",
                "ru": "Управление",
                "en": "Management",
                "uz": "Menejment",
                "icon": manageUserIcon
            },
            {
                "code": "603.02",
                "ru": "Маркетинг",
                "en": "Marketing",
                "uz": "Marketing",
                "icon": marketingIcon
            },
            {
                "code": null,
                "ru": "Русский язык в группах других языков",
                "en": "Russian Language in Other Language Groups",
                "uz": "O’zga tili guruhlarda rus tili",
                "icon": golobalIcon
            }
        ],
        [
            {
                "code": null,
                "ru": "Психология и педагогика дошкольного образования",
                "en": "Preschool Education Psychology and Pedagogy",
                "uz": "Maktabgacha ta’lim psixologiyasi va pedagogikasi",
                "icon": headIcon
            },
            {
                "code": null,
                "ru": "Бухгалтерский учёт и аудит",
                "en": "Accounting and Auditing",
                "uz": "Buxgalteriya hisobi va audit",
                "icon": CalIcon
            },
            {
                "code": null,
                "ru": "Компьютерная инженерия",
                "en": "Computer Engineering",
                "uz": "Kompyuter injiniringi",
                "icon": KiICon
            }
        ],
        [
            {
                "code": null,
                "ru": "Инжиниринг кибербезопасности",
                "en": "Cybersecurity Engineering",
                "uz": "Kibervafvsizlik injiniringi",
                "icon": KbiIcon
            },
            {
                "code": null,
                "ru": "Технология машиностроения, оборудование и автоматизация",
                "en": "Machine Building Technology, Manufacturing and Automation",
                "uz": "Mashinasozlik texnologiyasi, ishlab chiqarish va avtomatlashtirish",
                "icon": SetIcon
            }
        ],
        [
            {
                "code": null,
                "ru": "Оборудование и технология машиностроения",
                "en": "Transport Vehicle Engineering",
                "uz": "Transport vositalari muhandisligi",
                "icon": CarIcon
            },
            {
                "code": null,
                "ru": "Начальное образование",
                "en": "Primary Education",
                "uz": "Boshlang’ich ta’lim",
                "icon": BookIcon
            },
            {
                "code": null,
                "ru": "Банковское дело и аудит",
                "en": "Banking and Auditing",
                "uz": "Bank ishi va auditi",
                "icon": BankIcon
            },
            {
                "code": null,
                "ru": "Электроэнергетика",
                "en": "Electric Power Engineering",
                "uz": "Elektr energetikasi",
                "icon": enrgyIcon
            }
        ],
        [
            {
                "code": null,
                "ru": "Инженерия транспортных средств",
                "en": "Transport Vehicle Engineering",
                "uz": "Transport vositalari muhandisligi",
                "icon": masterSetIcon
            },
            {
                "code": null,
                "ru": "Транспортная логистика",
                "en": "Transport Logistics",
                "uz": "Transport logistikasi",
                "icon": arrowBoxIcon
            },
            {
                "code": null,
                "ru": "Спортивная деятельность (бокс)",
                "en": "Sports Activity (Boxing)",
                "uz": "Sport faoliyati (boks)",
                "icon": BollIcon
            },
            {
                "code": null,
                "ru": "Педагогика и психология",
                "en": "Pedagogy and Psychology",
                "uz": "Pedagogika va psixologiya",
                "icon": MenuBookIcon
            }
        ]
    ];



    return loading ? <Loading /> : (
        <>
            <div className={"rightMenu"}>
                <div className="rightMenuStudent" style={{ transform: openStudentMenu ? 'translateX(-100vw)' : null }}>
                    <button className={openMenu ? 'noneBtn' : btnClassStatus}>
                        <img src={menu2} alt="" />
                        <span onClick={() => setOpenStudentMenu(true)}>{leng == 'uz' ? "Talabalar" : leng == 'ru' ? "Студенты" : "Students"}</span>
                    </button>
                    <div className="rightMenuStudent_menu">
                        <div className="rightMenuStudent_menu_info">
                            <div className="rightMenuStudent_menu_info_left">
                                <h1>{currentTranslations.loginToAccess}</h1>
                                <Link to={"https://student.utu-ranch.uz/dashboard/login"}>
                                    <button>{currentTranslations.login}</button>
                                </Link>
                            </div>
                            <div className="rightMenuStudent_menu_info_right">
                                <img src={gif} alt="" />
                            </div>
                        </div>
                        <button onClick={closeRightStudentMenu} className={openStudentMenu ? 'rightMenuCloseBtn btnAnim' : 'rightMenuCloseBtn noneBtn'}>
                            <img src={nazadMenu} alt="" />
                            <span><RxExit /></span>
                        </button>
                    </div>
                </div>
                <div className="rightMenuMenu" style={{ transform: openMenu ? 'translateX(-110vw)' : 'translateX(0vw)' }}>
                    <button className={openStudentMenu ? 'noneBtn' : btnClassStatus}>
                        <img src={menu1} alt="" />
                        <span onClick={() => setOpenMenu(true)}>{leng == 'uz' ? "Menyu" : leng == 'ru' ? "Меню" : "Menu"}</span>
                    </button>
                    <div style={{ display: openStudentMenu ? 'none' : openMenu ? '' : '' }} className="rightMenuMenu_menu">
                        <img src={menuBg} alt="" />
                        <div className="rightMenuMenu_menu_menus">
                            <ul>
                                <span>{currentTranslations.news}</span>
                                <Link to={'/newsAll'}><li>{currentTranslations.news}</li></Link>
                                <Link to={'/publicNews'}><li>{currentTranslations.publicEvents}</li></Link>
                                <Link to={'/newsUnversity'}><li>{currentTranslations.university}</li></Link>
                                <Link to={'/xalqaroNews'}><li>{currentTranslations.internationalRelations}</li></Link>
                            </ul>
                            <ul>
                                <span>{currentTranslations.university}</span>
                                <Link to={'/about'}><li>{currentTranslations.about}</li></Link>
                                <Link to={'/department'}><li>{currentTranslations.departments}</li></Link>
                                <Link to={'/departments'}><li>{currentTranslations.centers}</li></Link>
                                <Link to={'/rektorat'}><li>{currentTranslations.rectorate}</li></Link>
                                <Link to={'/rules'}><li>{currentTranslations.rules}</li></Link>
                                <Link to={'/rulesEtika'}><li>{currentTranslations.ethics}</li></Link>
                                <Link to={'/regulation'}><li>{currentTranslations.charter}</li></Link>
                            </ul>
                            <ul>
                                <span>{currentTranslations.abutrient}</span>
                                <Link to={'/kunduzgi'}><li>{currentTranslations.fullTime}</li></Link>
                                <Link to={'/sirtqi'}><li>{currentTranslations.correspondence}</li></Link>
                                <Link to={'https://qabul.utu-ranch.uz'}><li>{currentTranslations.onlineAdmission}</li></Link>
                            </ul>
                            <ul>
                                <span>HEMIS</span>
                                <Link to={'https://student.utu-ranch.uz'}><li>HEMIS</li></Link>
                            </ul>
                            <ul>
                                <span>{currentTranslations.master}</span>
                                <Link to={'/magistratura'}><li>{currentTranslations.master}</li></Link>
                            </ul>
                            <ul>
                                <span>{currentTranslations.resources}</span>
                                <Link to={'https://edu.utu-ranch.uz/'}><li>{currentTranslations.eLibrary}</li></Link>
                            </ul>
                        </div>

                        <button onClick={closeMenu} className={openMenu ? 'rightMenuCloseBtn btnAnim' : 'rightMenuCloseBtn noneBtn'}>
                            <img src={nazadMenu} alt="" />
                            <span><RxExit /></span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="Home">
                <div class="Home_title_png">
                    <img className={leng == 'uz' ? "imgtop" : leng == 'en' ? "imgtop" : null} src={leng == 'uz' ? haqiqat : leng == 'ru' ? title_ru : title_en} alt="Title Image" />
                </div>
                <div className="Home_Images">
                    <div className="Home_Images_userImg">
                        {images[0] && <img src={images[0].ranch_yuzi} alt="User" />}
                    </div>
                    <div className="Home_Images_ranchImage">
                        <div className="Home_Images_ranchImage_after">
                            {images[0] && <img src={images[0].zdaniya_kunduzgi} alt="Building Daytime" />}
                        </div>
                        <div className="Home_Images_ranchImage_before">
                            {images[0] && <img src={images[0].zdaniya_gechki} alt="Building Nighttime" />}
                        </div>
                    </div>
                    <div className="Home_Images_logo">
                        <img src={ranchLogoReft1} alt="" />
                        <img src={ranchLogoReft2} alt="" />
                        <img src={ranchLogoReft3} alt="" />
                    </div>
                </div>
                <div className="headerContact">
                    <div className="headerContact_contact">
                        <div className="headerContact_contact_phone">
                            <img src={operatorSmile} alt="" />
                            <p>(62) 227 77 72</p>
                        </div>
                        <div className="headerContact_contact_mIcon">
                            <a href="https://www.instagram.com/utu_ranch?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><img src={instagramIcon} alt="" /></a>
                            <a href="https://t.me/UTU_Ranch"><img src={telegramIcon} alt="" /></a>
                            <a href="https://youtube.com/@uturanch?si=K0lmi49i4JRO74uc"><img src={youtubeIcon} alt="" /></a>
                            <a href="university@utu-ranch.uz"><img src={mailIcon} alt="" /></a>
                        </div>
                    </div>
                    <div className="headerContact_line"></div>
                </div>
            </div>
            <div className="home_about_Con">
                <div className="home_about">
                    <p className="home_about_title">{currentTranslations.weHave}</p>
                    <div className="home_about_about">
                        <div className="home_about_about_left">
                            <img src={ranchLogoReft1} alt="" />
                            <img src={ranchLogoReft2} alt="" />
                            <img src={ranchLogoReft3} alt="" />
                        </div>
                        <div className="home_about_about_main">
                            <div>
                                <span>80</span>
                                <p className='home_about_about_main_thin'>{currentTranslations.teachers}</p>
                            </div>
                            <div>
                                <span>35</span>
                                <p className='home_about_about_main_thin'>{currentTranslations.popularFields}</p>
                            </div>
                            <div>
                                <span>4 160</span>
                                <p className='home_about_about_main_thin'>{currentTranslations.coolStudents}</p>
                            </div>
                        </div>
                        <div className="home_about_about_right">
                            <img src={ranchLogoReft1} alt="" />
                            <img src={ranchLogoReft2} alt="" />
                            <img src={ranchLogoReft3} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="homeSwiper">
                <p>{currentTranslations.studentLife}</p>
                {
                    images.length > 0 ? (
                        <HomeAboutSwiper item={images[0].contenttable} />
                    ) : null
                }
            </div>
            <Link className='instaLink' to={'https://www.instagram.com/utu_ranch?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='}>
                <div className="instaAnimation">
                    <img src={instaEffect} alt="" />
                    <img src={instagramIcon} alt="" />
                    <p>@utu-ranch</p>
                </div>
            </Link>
            <div className="home_zyafka">
                <div className="home_zyafka_box">
                    <div className="home_zyafka_box_rigth">
                        <img src={usernot} alt="" />
                    </div>
                    <div className="home_zyafka_box_left">
                        <div className="home_zyafka_box_left_texts">
                            <p>{currentTranslations.onlineAdmissionTitle}</p>
                            <p>{currentTranslations.onlineAdmissionPrompt}</p>
                        </div>
                        <Link to={'https://qabul.utu-ranch.uz'}>
                            <button>{currentTranslations.submissionDocuments}</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="resurce">
                <div className="resurce_con">
                    <p className="resurce_title">{currentTranslations.resources}</p>
                    <div className="resurce_boxes">
                        <div className="resurce_boxes_top">
                            <Link to={"https://student.utu-ranch.uz/dashboard/login"}>
                                <div className="resurce_boxes_top_box">
                                    <p>HEMIS</p>
                                    <img src="https://s3-alpha-sig.figma.com/img/512a/0505/509eebbaea4c6cfe063aeabf29f2af94?Expires=1721001600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eQAvSF74ZHcQmtmUyACkLCRO~Lq2ezXcnj6upmxAL6p3drCAbqlwJ2aRnMIoJpooGeSqdgcWq7EpXMHdov6Pf3F0cFuOjVi2GxiGK4KH9Vb3NmraBaZBMhHCKgG9fRuD5~yVbt~OTVS-UJOu8G6yzyQz7t4ngwFypSAJuUqo9wsCLiGn1~SoIWlXWKSvHDBqLj0kKVVjVhR~FFp1KtMAeIM7qyqxo-vmCwE4Ns2DrK7P4YML4FfM2qN7HLDLzor1GXsqe9YJ5SjTOrchz9xKRJsySqFmTWhigi1s7DHpbvLgRUs9MkiOZgUlZ-cgUwZ9l0GnwRR~B4R31SrWLtwihw__" alt="" />
                                </div>
                            </Link>
                            <Link to={"https://edu.utu-ranch.uz/"}>
                                <div className="resurce_boxes_top_box">
                                    <p>{currentTranslations.eLibrary}</p>
                                </div>
                            </Link>
                            <Link>
                                <div className="resurce_boxes_top_box skoroBlur">
                                    <h4>{leng == 'uz' ? "Tez Orada" : leng == 'ru' ? "Cкоро" : "Soon"}</h4>
                                    <p>Расписание занятий</p>
                                </div>
                            </Link>
                        </div>
                        <div className="resurce_boxes_bottom">
                            <div className="resurce_boxes_bottom_box skoroBlur">
                                <h4>{leng == 'uz' ? "Tez Orada" : leng == 'ru' ? "Cкоро" : "Soon"}</h4>
                                <p>Cкачать контракт</p>
                            </div>
                            <div className="resurce_boxes_bottom_box skoroBlur">
                                <h4>{leng == 'uz' ? "Tez Orada" : leng == 'ru' ? "Cкоро" : "Soon"}</h4>
                                <p>Письмо с подтверждением</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="newsTop">
                <div className="newsTopTitle">
                    <img src={text50} alt="" />
                    <img src={text50Bbg} alt="" />
                </div>
            </div>
            <div className="news">
                <div className="sticky-element">
                    {/* Matn harflarini ajratib olish */}
                    {Array.from(newsTitle).map((char, index) => (
                        <span
                            key={index}
                            style={{
                                WebkitTextFillColor: calculateColor(index),
                                transition: 'color 0.3s ease-out',
                            }}
                        >
                            {char}
                        </span>
                    ))}
                </div>
                <Link to={'/newsAll'}><button className='newsBtn'>{currentTranslations.moreNews}</button></Link>
                <div className="news_content">
                    <div className="news_content_boxes">
                        {newsData && newsData[0] ? (
                            <Link to={`/news/${newsData[0].id}`}>
                                <img
                                    src={newsData[0].image}
                                    alt={newsData[0][`title_${leng}`]}
                                />

                            </Link>
                        ) : (
                            <img src="NoneImg" alt="No Image Available" />
                        )}
                        <p className="news_content_boxes_title">
                            {newsData && newsData[0] ? newsData[0][`title_${leng}`] : 'Default Title'}
                        </p>
                        <div className="news_content_boxes_footer">
                            <span>10/06/24</span>
                            <span><IoEyeSharp />162</span>
                        </div>
                    </div>

                    <div className="news_content_boxes">
                        {newsData && newsData[1] ? (
                            <Link to={`/news/${newsData[1].id}`}>
                                <img src={newsData[1].image} alt={newsData[1].title_uz} />
                            </Link>
                        ) : (
                            <img src="NoneImg" alt="No Image Available" />
                        )}
                        <p className="news_content_boxes_title">
                            {newsData && newsData[1] ? newsData[1][`title_${leng}`] : 'Default Title'}
                        </p>
                        <div className="news_content_boxes_footer">
                            <span>10/06/24</span>
                            <span><IoEyeSharp />162</span>
                        </div>
                    </div>
                    <div className="news_content_boxes">
                        {newsData && newsData[2] ? (
                            <Link to={`/news/${newsData[2].id}`}>
                                <img src={newsData[2].image} alt={newsData[2].title_uz} />
                            </Link>
                        ) : (
                            <img src="NoneImg" alt="No Image Available" />
                        )}
                        <p className="news_content_boxes_title">
                            {newsData && newsData[2] ? newsData[2][`title_${leng}`] : 'Default Title'}
                        </p>
                        <div className="news_content_boxes_footer">
                            <span>10/06/24</span>
                            <span><IoEyeSharp />162</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="study">
                <p className="study_title">{currentTranslations.fieldsOfStudy}</p>
                <div className="study_btnAnim">
                    {textYonalish.map((group, groupIndex) => (
                        <div key={groupIndex} className={`study_btnAnim_line${groupIndex % 2 === 0 ? '' : '_R'}`}>
                            {group.map((item, itemIndex) => (
                                <button key={itemIndex}>
                                    <img src={item.icon} alt="" />
                                    {item[leng]}
                                </button>
                            ))}
                        </div>
                    ))}
                </div>
                <Link className="study_btnBottm" to={'/napravleniya'}><button>{currentTranslations.details}</button></Link>
            </div>
            <div className="plan">
                <div className="plan_video">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/sy8lpKZPkp8" // to'g'ri 'embed' URL format
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; picture-in-picture"
                        allowFullScreen
                        rel='0'
                    ></iframe>
                </div>
                <div className="plan_imageUn">
                    <img src={unBg} alt="" />
                    <img src={ranchBgRes} alt="" />
                    <img src={unImg2} alt="" />
                    <img src={ranchRes} alt="" />
                </div>
                <div className="plan_indfos">
                    <div className="plan_indfos_boxes">
                        <div className="plan_indfos_box">
                            <div className="plan_indfos_box_left">
                                <h1>01</h1>
                                <p>{currentTranslations.newCampusDevelopment}</p>
                            </div>
                            <div className="plan_indfos_box_right">
                                <img src={plan1} alt="" />
                                <img src={plan2} alt="" />
                                <img src={plan3} alt="" />
                                <img src={plan4} alt="" />
                            </div>
                        </div>
                        <div className="plan_indfos_box">
                            <div className="plan_indfos_box_left">
                                <h1>02</h1>
                                <p>{currentTranslations.sportsInfrastructure}</p>
                            </div>
                            <div className="plan_indfos_box_right">
                                <img src={plan2} alt="" />
                                <img src={plan1} alt="" />
                                <img src={plan3} alt="" />
                                <img src={plan4} alt="" />
                            </div>
                        </div>
                        <div className="plan_indfos_box">
                            <div className="plan_indfos_box_left">
                                <h1>03</h1>
                                <p>{currentTranslations.advancedTechLearning}</p>
                            </div>
                            <div className="plan_indfos_box_right">
                                <img src={plan3} alt="" />
                                <img src={plan1} alt="" />
                                <img src={plan2} alt="" />
                                <img src={plan4} alt="" />
                            </div>
                        </div>
                        <div className="plan_indfos_box">
                            <div className="plan_indfos_box_left">
                                <h1>04</h1>
                                <p>{currentTranslations.internationalCooperation}</p>
                            </div>
                            <div className="plan_indfos_box_right">
                                <img src={plan4} alt="" />
                                <img src={plan1} alt="" />
                                <img src={plan2} alt="" />
                                <img src={plan3} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="plan_indfos_right">
                        {Array.from(newTitle2).map((char, index) => (
                            <span
                                key={index}
                                style={{
                                    WebkitTextFillColor: calculateColor2(index),
                                    transition: 'color 0.3s ease-out',
                                }}
                            >
                                {char}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
            <div className="partnor">
                <p className="partnor_title">{currentTranslations.partners}</p>
                <Swiper
                    spaceBetween={30}  // Slaydlar orasidagi masofa
                    slidesPerView={3}  // Bir vaqtning o'zida ko'rsatiladigan slaydlar soni
                    autoplay={{
                        delay: 1000,    // Slaydlar orasidagi vaqt (ms)
                        disableOnInteraction: false,
                    }}
                    loop={true} // Cheksiz aylanish
                    breakpoints={{
                        // Responsivlik uchun sozlamalar
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 7,
                            spaceBetween: 30,
                        },
                    }}
                >
                    {dataPartNor.map((item, index) => (
                        <SwiperSlide key={index}>
                            <Link to={item.url_link}>
                                <img src={item.image} alt={item.name} />
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="footer">
                <div className="map">
                    <div className="mapForm">
                        <p className="mapForm_title">{leng == 'uz' ? "Aloqa" : leng == 'ru' ? "Связаться" : "Contact"}</p>
                        <a href=""><button><MdPhonelinkRing /> (62) 227 77 72</button></a>
                        <a href=""><button><MdMailOutline /> university@utu-ranch.uz</button></a>
                        <div className="mapForm_info">
                            <p>{leng == 'uz' ? "Ish kunlari:" : leng == 'ru' ? "Рабочие дни:" : "Working days:"}</p>
                            <p>{leng == 'uz' ? "Dushanba - Shanba:" : leng == 'ru' ? "Понедельник - Суббота:" : "Monday - Saturday:"} 9:00 - 18:00</p>
                            <p>{leng == 'uz' ? "Yakshanba: dam oling" : leng == 'ru' ? "Воскресень: Отдых" : "Sunday: Rest"}</p>
                        </div>
                    </div>
                    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac563d5ce2683af91dc5c32336920c183457c2dd841d04e5d4c2e6e76d86c2e11&amp;source=constructor" width="1140" height="600" frameborder="0"></iframe>
                </div>
                <Footer />
            </div>
        </>
    );
}

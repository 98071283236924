import Btns from "../../components/btns/Btns";
import { useContext, useState } from "react";
import AppContext from "../../context/AppContext";
// import './yonalish.css'




export default function Magister() {

    // btn set
    const [activeButton, setActiveButton] = useState(1);

    const buttons = [
        {
            id: 1,
            name_uz: "Magistratura",
            name_ru: 'Магистратура',
            name_en: "Master's degree",
            link: '/magistratura',
        },
    ]

    const data = [
        {
            "code": "70310102",
            "name": "Iqtisodiyot (tarmoqlar va sohalar bo'yicha)",
            "price": "19 824 000"
        },
        {
            "code": "70610501",
            "name": "Kompyuter injiniringi (Amaliy dasturiy vositalarni loyihalash)",
            "price": "20 496 000"
        },
        {
            "code": "70610501",
            "name": "Kompyuter injiniringi (Axborot va multimedia texnologiyalari)",
            "price": "20 496 000"
        },
        {
            "code": "70610501",
            "name": "Kompyuter injiniringi (Axborot xavfsizligi, Kriptografiya va Kriptoanaliz)",
            "price": "20 496 000"
        },
        {
            "code": "70610501",
            "name": "Kompyuter injiniringi (Kompyuter tizimlarini loyuhalash)",
            "price": "20 496 000"
        },
        {
            "code": "70230101",
            "name": "Lingvistika (Ingliz tili)",
            "price": "23 072 000"
        },
        {
            "code": "70230101",
            "name": "Lingvistika (o'zbek tili)",
            "price": "23 072 000"
        },
        {
            "code": "70230101",
            "name": "Lingvistika (rus tili)",
            "price": "23 072 000"
        },
        {
            "code": "70720801",
            "name": "Mashinasozlik texnologiyasi va jihozlari (ishlab chiqarish bo'yicha)",
            "price": "19 824 000"
        },
        {
            "code": "70110102",
            "name": "Pedogogika va psixologiya",
            "price": "23 072 000"
        }
    ]

    const { leng } = useContext(AppContext)

    return (
        <div className="yonalish">
            <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
            <div className="categoryYonlish">
                <h2 className="categoryYonlish_title">{leng == 'uz' ? "Magistratura" : leng == 'ru' ? "Магистратура" : "Master's degree"}</h2>
                <div className="categoryYonlish_lists">
                    <div className="categoryYonlish_lists_list">
                        <span>{leng == 'uz' ? "Yo'nalish kodi" : leng == 'ru' ? "Код направления" : "Direction code"}</span>
                        <span>{leng == 'uz' ? "Yo'nalishlar nomi" : leng == 'ru' ? "Hаправления" : "Transfer of learning"}</span>
                        <span>{leng == 'uz' ? "Shartnoma narxi" : leng == 'ru' ? "Сумма контракта" : ""}</span>
                    </div>
                    {
                        data.map(item => (
                            <div className="categoryYonlish_lists_list">
                                <span>{item.code}</span>
                                <span>{item.name}</span>
                                <span>{item.price} сум</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}   
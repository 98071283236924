import { useState } from "react";
import Btns from "../../components/btns/Btns";
import img1 from '../../image/ustavImg/page1.png'
import img2 from '../../image/ustavImg/page2.png'
import './regulation.css'


export default function Regulation() {

    // btn set
    const [activeButton, setActiveButton] = useState(7);

    const buttons = [
        {
            id: 1,
            name_uz: 'Universitet haqida',
            name_ru: 'О университете',
            name_en: 'About University',
            link: '/about',
        },
        {
            id: 2,
            name_uz: 'Kafedralar',
            name_ru: 'Кафедры',
            name_en: 'Departments',
            link: '/department',
        },
        {
            id: 3,
            name_uz: 'Markaz va boʻlimlar',
            name_ru: 'Центры и Отделы',
            name_en: 'Centers and other departments',
            link: '/departments',
        },
        {
            id: 4,
            name_uz: 'Rektorat',
            name_ru: 'Ректорат',
            name_en: 'University administration',
            link: '/rektorat',
        },
        {
            id: 5,
            name_uz: 'Universitet qoidalari',
            name_ru: 'Правила Университета',
            name_en: 'University Rules',
            link: '/rules',
        },
        {
            id: 6,
            name_uz: 'Axloq kodeksi',
            name_ru: 'Кодекс этики',
            name_en: 'Code of Ethics',
            link: '/rulesEtika',
        },
        {
            id: 7,
            name_uz: 'Universitet nizomi',
            name_ru: 'Устав Университета',
            name_en: 'University charter',
            link: '/regulation',
        }
    ]

    return (
        <div className="ustav">
            <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
            <div className="ustavImgs">
                <img src={img1} alt="" />
                <img src={img2} alt="" />
            </div>
        </div>
    )
}
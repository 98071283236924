import './table_styles.css';


export default function Tables() {
    return (
        <>
        <div className='fixtpos'>
                <div className='headpos'>
                    <p>Магистратура</p>
                </div>    
            <div class="tabl">
                
                <table>
                    <thead>
                        <tr>
                            <th>Код направления</th>
                            <th>Hаправления</th>
                            <th>Сумма контракта</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Test</td>
                            <td>Test</td>
                            <td>Test</td>
                        </tr>
                        <tr>
                            <td>Test</td>
                            <td>Test</td>
                            <td>Test</td>
                        </tr>
                        <tr>
                            <td>Test</td>
                            <td>Test</td>
                            <td>Test</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        </>
    )
}
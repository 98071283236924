import Btns from "../../components/btns/Btns";
import "../../components/btns/btns_styles.css";
import { useState } from "react";
import { IoEyeSharp } from "react-icons/io5";
import './news.css';
import newsImg from '../../image/news/news.png'
import newsImg2 from '../../image/news/news2.png'
import newsImg3 from '../../image/news/news3.png'
import MainNews from "../../components/news_components/MainNews";
import MoreNewsMini from "../../components/news_components/MoreNewsMini";
import { Link } from "react-router-dom";
import newUn from '../../image/news/newUn.png'
import RandomNews from "./RandomNews";

export default function XalqaroNews() {
  const [activeButton, setActiveButton] = useState(4);

  const buttons = [
    {
      id: 1,
      name_uz: 'Yangiliklar',     // O'zbek tilida
      name_ru: 'Новости',         // Rus tilida
      name_en: 'News',            // Ingliz tilida
      link: '/newsAll',
  },
  {
      id: 2,
      name_uz: 'Ommaviy tadbirlar',  // O'zbek tilida
      name_ru: 'Публичные мероприятия',  // Rus tilida
      name_en: 'Public Events',          // Ingliz tilida
      link: '/publicNews',
  },
  {
      id: 3,
      name_uz: 'Universitet',     // O'zbek tilida
      name_ru: 'Университет',     // Rus tilida
      name_en: 'University',      // Ingliz tilida
      link: '/newsUnversity',
  },
  {
      id: 4,
      name_uz: 'Xalqaro munosabatlar',  // O'zbek tilida
      name_ru: 'Международные отношения',  // Rus tilida
      name_en: 'International Relations',  // Ingliz tilida
      link: '/xalqaroNews',
  }
  ]

  // text length
  const [text, setText] = useState('К 1 ИЮНЯ «МЕЖДУНАРОДНОМУ ДНЮ ЗАЩИТЫ ДЕТЕЙ» ПРЕПОДАВАТЕЛИ КАФЕДРЫ ПЕДАГОГИКИ И ФИЛОЛОГИИ ПО ИНИЦИАТИВЕ РОЗМЕТОВОЙ С.В., ПРЕДСЕДАТЕЛЯ КОМИТЕТА ЖЕНЩИН ТЕХНОЛОГИЧЕСКОГО УНИВЕРСИТЕТА УРГЕНЧ-РАНЧ');

  return (
    <div className="news_page">
      <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
      <div className="news_page_main">
        <div className="news_page_main_news">
          <img src={newsImg3} alt="" />
          <h4 className="title">
            К 1 ИЮНЯ «МЕЖДУНАРОДНОМУ ДНЮ ЗАЩИТЫ ДЕТЕЙ» ПРЕПОДАВАТЕЛИ КАФЕДРЫ ПЕДАГОГИКИ И ФИЛОЛОГИИ ПО ИНИЦИАТИВЕ РОЗМЕТОВОЙ С.В., ПРЕДСЕДАТЕЛЯ КОМИТЕТА ЖЕНЩИН ТЕХНОЛОГИЧЕСКОГО УНИВЕРСИТЕТА УРГЕНЧ-РАНЧ
          </h4>
          <div className="date_view">
            <p>10/06/24</p>
            <span><IoEyeSharp />162</span>
          </div>
          <p className="desc">
            По случаю праздника «Международный день защиты детей» 1 июня по инициативе С. Розметовой, председателя Комитета женщин Технологического университета Ургенч-Ранч, преподавателей кафедры педагогики и Филолог Н.Мадрахимова, Н.Джаббаровы посетили специализированные дошкольные образовательные организации №22 и №37 города Ургенча, где вручили праздничные подарки обучающимся там детям.

            ✅Ранч – университет больших возможностей!
          </p>
        </div>
      </div>
      <RandomNews />
    </div>
  );
}

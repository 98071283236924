
import MoreNewsMini from '../../components/news_components/MoreNewsMini';
import './test_styles.css';


export default function Test() {
    return (
        <>
            <MoreNewsMini />
        </>
    )
}
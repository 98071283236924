import { Link } from 'react-router-dom';
import Btns from '../../components/btns/Btns'
import './department.css'
import { useContext, useEffect, useState } from 'react';
import kafedrImg from '../../image/kafedr.png'
import axios from 'axios';
import AppContext from '../../context/AppContext';


export default function Department() {

    // btn set
    const [activeButton, setActiveButton] = useState(2);

    const buttons = [
        {
            id: 1,
            name_uz: 'Universitet haqida',
            name_ru: 'О университете',
            name_en: 'About University',
            link: '/about',
        },
        {
            id: 2,
            name_uz: 'Kafedralar',
            name_ru: 'Кафедры',
            name_en: 'Departments',
            link: '/department',
        },
        {
            id: 3,
            name_uz: 'Markaz va boʻlimlar',
            name_ru: 'Центры и Отделы',
            name_en: 'Centers and other departments',
            link: '/departments',
        },
        {
            id: 4,
            name_uz: 'Rektorat',
            name_ru: 'Ректорат',
            name_en: 'University administration',
            link: '/rektorat',
        },
        {
            id: 5,
            name_uz: 'Universitet qoidalari',
            name_ru: 'Правила Университета',
            name_en: 'University Rules',
            link: '/rules',
        },
        {
            id: 6,
            name_uz: 'Axloq kodeksi',
            name_ru: 'Кодекс этики',
            name_en: 'Code of Ethics',
            link: '/rulesEtika',
        },
        {
            id: 7,
            name_uz: 'Universitet nizomi',
            name_ru: 'Устав Университета',
            name_en: 'University charter',
            link: '/regulation',
        }
    ]

    const [data, setData] = useState([])

    const getData = async () => {
        const response = await axios.get(`https://new.utu-ranch.uz/category/kafedrashu/`)
        setData(response.data)
    }

    useEffect(() => {
        getData()
    }, [])

    const { leng } = useContext(AppContext);

    return (
        <>
            <div className="department">
                <Btns activeButton={activeButton} setActiveButton={setActiveButton} buttons={buttons} />
                {
                    data.map(item => (
                        <div className="department_box">
                            <div className="department_box_img">
                                <img src={item.image} alt="" />
                            </div>

                            <div className="department_box_menu">
                                <p className="department_box_menu_title">{item[`name_${leng}`]}</p>
                                <ul>
                                    {
                                        item.children_kafedra.map(info => (
                                            <Link to={`/department/${info.id}`}><li>{info[`name_${leng}`]}</li></Link>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}
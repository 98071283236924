import './enterence_styles.css';
import enterencyText from '../../image/enterenceWords.svg'
import hemisImg from '../../image/hemisbtn.svg'
import rssImg from '../../image/rssbtn.svg' 

export default function Enterence() {
    return (
        <>
            <div className='entposon'>
                <div id="enterence_frame">
                        <img src={enterencyText} alt="" />
                        <div class="enterence_btn">
                            <a href="#" id="hemis"><img src={hemisImg} alt="" /></a>
                            <a href="#" id="rss"><img src={rssImg} alt="" /></a>
                    </div>
                </div>
            </div>
        
        
        </>

    )
}
import { useEffect } from 'react';

const GradientBody = ({ gradient }) => {
    useEffect(() => {
        document.body.style.background = gradient;
        return () => {
            document.body.style.background = null; 
        };
    }, [gradient]);

    return null; 
};

export default GradientBody;
import './study.css'
import garphLine from '../../image/studyIcons/garphLine.svg'
import monyIcon from '../../image/studyIcons/money.svg'
import manageUserIcon from '../../image/studyIcons/manageUser.svg'
import marketingIcon from '../../image/studyIcons/marketingIcon.svg'
import golobalIcon from '../../image/studyIcons/globalIcon.svg'
import headIcon from '../../image/studyIcons/miyaIcon.svg'
import CalIcon from '../../image/studyIcons/Calculate.svg'
import KiICon from '../../image/studyIcons/KiIcon.svg'
import KbiIcon from '../../image/studyIcons/KbIIcon.svg'
import SetIcon from '../../image/studyIcons/setIcon.svg'
import CarIcon from '../../image/studyIcons/carIcon.svg'
import BookIcon from '../../image/studyIcons/bookIcon.svg'
import BankIcon from '../../image/studyIcons/bankIcon.svg'
import enrgyIcon from '../../image/studyIcons/enrgyIcon.svg'
import masterSetIcon from '../../image/studyIcons/masterSet.svg'
import arrowBoxIcon from '../../image/studyIcons/arrowBoxIcon.svg'
import BollIcon from '../../image/studyIcons/bollIcon.svg'
import MenuBookIcon from '../../image/studyIcons/menuBook.svg'




export default function Diredu() {
  return (
    <>
    <div className="studies">
        <p className="studies_title">НАПРАВЛЕНИЯ ОБУЧЕНИЯ</p>
        <div className="studies_btnAnim">
            <div className="studies_btnAnim_line">
                <button><img src={garphLine} alt="" />Экономика</button>
                <button><img src={monyIcon} alt="" />Бухгалтерский учёт и аудит</button>
                <button><img src={manageUserIcon} alt="" />Управление</button>
                <button><img src={marketingIcon} alt="" />Маркетинг</button>
                <button><img src={golobalIcon} alt="" />Филология и обучение языкам</button>
            </div>
            <div className="studies_btnAnim_line_R">
                <button><img src={headIcon} alt="" />Психология и педагогика дошкольного образования</button>
                <button><img src={CalIcon} alt="" />Бухгалтерский учёт и аудит</button>
                <button><img src={KiICon} alt="" />Компьютерная инженерия</button>
            </div>
            <div className="studies_btnAnim_line">
                <button><img src={KbiIcon} alt="" />Инжиниринг кибербезопасности</button>
                <button><img src={SetIcon} alt="" />Технология машиностроения, оборудование и автоматизация машиностроительного производства</button>
            </div>
            <div className="studies_btnAnim_line_R">
                <button><img src={CarIcon} alt="" />Оборудование и технология машиностроения</button>
                <button><img src={BookIcon} alt="" />Начальное образование</button>
                <button><img src={BankIcon} alt="" />Банковское дело и аудит</button>
                <button><img src={enrgyIcon} alt="" />Электроэнергетика</button>
            </div>
            <div className="studies_btnAnim_line">
                <button><img src={masterSetIcon} alt="" />Инженерия транспортных средств</button>
                <button><img src={arrowBoxIcon} alt="" />Транспортная логистика</button>
                <button><img src={BollIcon} alt="" />Спортивная деятельность</button>
                <button><img src={MenuBookIcon} alt="" />Педагогика и психология</button>
            </div>
        </div>
    </div>

    </>
  )
}
import React, { useState, useEffect } from 'react';

const ProgressBar = ({ targetProgress }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        if (newProgress >= targetProgress) {
          clearInterval(interval);
          return targetProgress;
        }
        return newProgress;
      });
    }, 100); // Har 100ms da progressni 10 ga oshiradi

    return () => clearInterval(interval); // Tozalash funksiyasi
  }, [targetProgress]);

  const containerStyles = {
    height: 10,
    width: '40%',
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: 50
  };

  const fillerStyles = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: '#F23C1C',
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 0.1s ease-in-out' // Animatsiya uchun
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        {/* <span style={labelStyles}>{`${progress}%`}</span> */}
      </div>
    </div>
  );
};

export default ProgressBar;

import './form_styles.css'
import certificate from '../../image/certificate.svg'
import certificate2 from '../../image/certificate2.svg'
import GradientBody from '../../components/gradientBody/GradientBody';


const gradient = 'linear-gradient(#0157FF, #2CB3FF)'; 


export default function Forma() {
    return (
        <>
        <GradientBody gradient={gradient} />
        <div class="pagebackground">
            <div class="BackOfForm">
                <p id="onlinedoc">Онлайн подача документов</p>
                <form action="#" method="post" className='formst'>
                <div className='fixpos'>
                    {/*  ФИО */ }
                    <h3>ФИО<span class="redassterics">*</span></h3>
                        <div className='fiobtn'>
                            <input type="text" placeholder="Фамилия"/>
                            <input type="text" placeholder="Имя"/>
                            <input type="text" placeholder="Отчество"/>
                        </div>
                        {/* /* Passport data */}
                    <div className='inlineheadthree'>
                        <div className="blockdata">
                            <h3>Паспортные данные<span class="redassterics">*</span></h3>
                            <input className='seria' type="text" placeholder="Серия"/>
                            <input className='passnum' type="text" placeholder="Номер"/>
                        </div>
                            {/* /* TODO: change text to phone */ }
                        <div className="blockdata">
                            <h3>Контактные данные<span class="redassterics">*</span></h3>
                            <input className='phonnum' type="tel" placeholder="+998(__) _ _ _ - _ _ - _ _"/>
                            <input className='emls' type="email" placeholder="Электронная почта" />
                        </div>
                    </div>
                        {/* /* TODO: change from text to date */}
                    <div className='inlineheadthree'>
                        <div className="blockdata">
                            <h3>Дата рождения<span class="redassterics">*</span></h3>
                            <input className='dates' type="date" value="Дата рождения" min="1900-01-01" max="2024-12-31" />
                        </div>
                            {/* // gender
                            /* TODO: Add name and placheolders */ }
                        <div className="blockdata">
                            <h3>Пол<span class="redassterics">*</span></h3>
                            <div className='sexstyles'>
                                <input id="man" type="radio" />
                                <input id="woman" type="radio" />
                                <div className='wrapedLabel'>
                                    <label for="man">Мужской</label>
                                    <label for="woman">Женский</label>
                                </div>
                            </div>
                        </div>
                    </div>
                        {/* // living place */}
                    <h3>Место проживание<span class="redassterics">*</span></h3>
                        <select className='liveplace'>
                            <option value="">Выберите</option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                            <option value=""></option>
                        </select>

                        {/* // choose learning time
                        // TODO: Complite all name */}
                    <h3>Выберите направление<span class="redassterics">*</span></h3>
                        <div className='studydirection'>
                            <input type="radio" id='dnevnaya' />
                            <input type="radio" name="" id="zaoch" />
                            <input type="radio" name="" id="mastery" />
                            <div className='wrapedLabel2'>
                                <label for="dnevnaya">Дневная</label>
                                <label for="zaoch">Заочно</label>
                                <label for="mastery">Магистратура </label>
                            </div>
                        </div>


                        {/* // chosing direction
                        // TODO: complite all options */}
                    <h3>Выберите направление обучения<span class="redassterics">*</span></h3>
                           <select className='liveplace'>                            
                                <option value="">Выберите</option>
                                <option value=""></option>
                                <option value=""></option>
                                <option value=""></option>
                                <option value=""></option>
                                <option value=""></option>
                                <option value=""></option>
                                <option value=""></option>
                                <option value=""></option>
                                <option value=""></option>
                                <option value=""></option>
                            </select>

                    

                    {/* // Personal Docs
                    // TODO: Complite Web Responsobility */}
                <h3>Личные Документы<span class="redassterics">*</span></h3>
                <div class="mainblock">
                        <div class="bagdoc">
                            <div class="docImg">
                                <img src={certificate} alt="" />
                                <p>или</p>
                                <img src={certificate2} />
                            </div>
                            <h4>Загрузите файл паспорта</h4>
                            <p>Загрузите только удостоверение личности или фотографию биометрического паспорта</p>
                            <div className='uploaddoc'>
                                <input type="file" name="" id="upload_doc" />
                                <label for="upload_doc" class="uploading upone">Загрузить</label>
                            </div>
                        </div>
                        <div class="bagdoc">
                            <img src={certificate} alt="" />
                            <h4>Загрузите файл сертификата</h4>
                            <p>Сертификат от школы</p>
                            <div className='uploaddoc'>
                                <input type="file" name="" id="upload_doc2"  />
                                <label for="upload_doc2" class="uploading uptwo">Загрузить</label>
                            </div>
                        </div>
                    </div>
                        <button id="subbutton" type="submit">Отправить</button>
                        </div>
                </form>

            </div>
        </div>
        </>

    )
}
import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './has.css';
import { Pagination, Autoplay } from 'swiper/modules';

export default function HomeAboutSwiper({ item = [] }) {
    const swiperRef = useRef(null);

    useEffect(() => {
        if (swiperRef.current) {
            const swiperInstance = swiperRef.current.swiper;

            const updateSlideOpacity = () => {
                const slides = swiperInstance.slides;
                const activeIndex = swiperInstance.activeIndex;

                slides.forEach((slide, index) => {
                    if (index === activeIndex) {
                        slide.style.opacity = '1';  // Full opacity for the active slide
                    } else {
                        slide.style.opacity = '0.5';  // Reduced opacity for others
                    }
                });
            };

            swiperInstance.on('slideChange', updateSlideOpacity);
            updateSlideOpacity(); // Initial call to set the opacity on load
        }
    }, []);

    return (
        <Swiper
            ref={swiperRef}
            slidesPerView={1.5} // Shows 1 full slide and part of next/previous
            centeredSlides={true} // Centers the slides
            spaceBetween={0} // No space between the slides
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 2000,
                disableOnInteraction: false,
            }}
            loop={true} // Infinite scrolling
            breakpoints={{
                320: {
                    slidesPerView: 1.2,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 5.5,  // Show 3 slides per view
                    spaceBetween: 30,
                },
            }}
            modules={[Autoplay]}
            className="mySwiper"
        >
            {item.length > 0 ? (
                item.map((data, index) => (
                    <SwiperSlide key={index}>
                        <img src={data.image} alt={`Slide ${index}`} />
                    </SwiperSlide>
                ))
            ) : (
                <SwiperSlide>No images available</SwiperSlide>
            )}
        </Swiper>
    );
}

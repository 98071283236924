import React, { useState, useEffect } from 'react';

export default function Test2() {

    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const div = document.getElementById('target-div');
            const rect = div.getBoundingClientRect();

            if (rect.top <= 0) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div>
                <div style={{ height: '1000px', paddingTop: '60px' }}>
                    Scroll down
                </div>
                <div id="target-div" style={{ height: '500px', backgroundColor: isFixed ? 'yellow' : 'lightblue', position: isFixed ? 'fixed' : 'static', top: isFixed ? '60px' : 'auto', width: '100%' }}>
                    Target div
                </div>
                <div style={{ height: '1000px' }}>
                    Content after div
                </div>
            </div>
        </>
    )
}
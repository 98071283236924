import { useContext } from 'react'
import './btns_styles.css'
import { Link } from 'react-router-dom'
import AppContext from '../../context/AppContext'


export default function Btns({buttons , setActiveButton , activeButton}) {

    const {leng} = useContext(AppContext);

    return (
        <>
            <div className="btnHeader">
                {buttons.map((item) => (
                    <Link key={item.id} to={item.link}>
                        <button
                            className={activeButton === item.id ? 'active' : ''}
                            onClick={() => setActiveButton(item.id)}
                        >
                            {item[`name_${leng}`]}
                        </button>
                    </Link>
                ))}
            </div>
        </>
    )
}